import Script from 'next/script';

declare global {
  interface Window {
    dataLayer: unknown[];
  }
}

export function GoogleAnalyticsTag() {
  const initialize = () => {
    window.dataLayer = window.dataLayer || [];

    window.gtag = function gtag() {
      // eslint-disable-next-line prefer-rest-params
      window.dataLayer.push(arguments);
    };

    gtag('js', new Date());
    gtag('config', 'UA-285902-24');
  };

  return (
    <Script
      id="google-analytics"
      src="https://www.googletagmanager.com/gtag/js?id=UA-285902-24"
      strategy="afterInteractive"
      onLoad={initialize}
    />
  );
}
