import { IconGlobe } from '@/assets/svg/icon-globe';
import { styled } from '@/styles/stitches.config';
import { localeLangMap } from '@/utils/i18n';
import { useDropdownMenu } from '@/utils/use-dropdown-menu';
import { Trans } from '@lingui/macro';
import { useRouter } from 'next/dist/client/router';
import { ReactNode } from 'react';
import { focusStyles, hoverStyles, visuallyHidden } from '../styles/predefined';
import { Button } from './elements/button';
import { LinkTo } from './link-to';

type LanguageDropdownProps = {
  direction?: 'up' | 'down';
  alignment?: 'left' | 'right';
};

const Root = styled('div', {
  display: 'flex',
  position: 'relative',
});

const Menu = styled('ul', {
  listStyleType: 'none',
  position: 'absolute',
  whiteSpace: 'nowrap',
  background: '$white',
  borderRadius: '$1',
  color: '$unicef',
  paddingBlock: '$2',
  paddingInline: '$3',
  boxShadow: '$1',
  margin: 0,
  variants: {
    direction: {
      up: {
        insetBlockEnd: 'calc(100% + $space$2)',
      },
      down: {
        insetBlockStart: 'calc(100% + $space$2)',
      },
    },
    alignment: {
      left: {
        insetInlineStart: 0,
      },
      right: {
        insetInlineEnd: 0,
      },
    },
  },
});

const MenuItem = styled('li', {
  marginBlockEnd: '$1',
});

const ButtonIcon = styled('span', {
  display: 'none',

  '@bp5': {
    display: 'flex',
    alignItems: 'center',
    marginInlineEnd: '$2',
  },
});

const ButtonContentsRoot = styled('span', {
  display: 'flex',
  alignItems: 'center',
});

const ButtonContentsSpan = styled('span', {
  '@media (pointer: fine)': {
    marginBlockStart: 1,
  },
});

const VisuallyHidden = styled('span', visuallyHidden);

interface ButtonContentsProps {
  children: ReactNode;
}

const ButtonContents = (props: ButtonContentsProps) => {
  return (
    <ButtonContentsRoot>
      <ButtonIcon>
        <IconGlobe />
      </ButtonIcon>
      <ButtonContentsSpan>{props.children}</ButtonContentsSpan>
    </ButtonContentsRoot>
  );
};

export function LanguageDropdown(props: LanguageDropdownProps) {
  const { alignment = 'left', direction = 'down' } = props;

  const router = useRouter();
  const { getButtonProps, getMenuProps, getRootProps } = useDropdownMenu<HTMLDivElement>();

  return (
    <Root {...getRootProps()}>
      <VisuallyHidden>
        <Trans id="common.language.select">Select your language</Trans>
      </VisuallyHidden>

      <Button
        css={{
          border: 'none',
          padding: 0,
          ...focusStyles,
          ...hoverStyles,
        }}
        {...getButtonProps()}
      >
        <ButtonContents>
          <VisuallyHidden>
            <Trans id="common.language.current">Current language: </Trans>
          </VisuallyHidden>
          {router?.locale && localeLangMap[router.locale as keyof typeof localeLangMap]}
        </ButtonContents>
      </Button>

      <Menu alignment={alignment} direction={direction} {...getMenuProps()}>
        {Object.entries(localeLangMap).map(([locale, language]) => (
          <MenuItem key={locale}>
            <LinkTo href={router.route} locale={locale} onClick={getButtonProps().onClick}>
              {language}
            </LinkTo>
          </MenuItem>
        ))}
      </Menu>
    </Root>
  );
}
