import { styled } from '@/styles/stitches.config';
import Link from 'next/link';

export const StyledLink = styled(Link, {
  color: 'currentColor',
  textDecoration: 'none',
  '&:hover': {
    color: '$blue',
    textDecoration: 'underline',
  },
  '&:focus-visible, &.focus-visible': {
    outlineColor: 'currentColor',
    outlineStyle: 'dotted',
    outlineWidth: 3,
  },
});
