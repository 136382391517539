import { useStore } from '@/store';
import { useEffect } from 'react';
import { useMediaQuery } from './use-media-query';

export function usePrefersReducedMotion(): void {
  const setPrefersReducedMotion = useStore((state) => state.setPrefersReducedMotion);
  const prefersReducedMotion = useMediaQuery('(prefers-reduced-motion: reduce)');

  useEffect(() => {
    setPrefersReducedMotion(prefersReducedMotion);
  }, [setPrefersReducedMotion, prefersReducedMotion]);
}
