import { useId } from 'react';
import { RefObject, useCallback, useRef, useState, useEffect } from 'react';
import useOnClickOutside from './use-on-click-outside';

type ButtonProps = {
  'aria-expanded': boolean;
  'aria-controls': string | undefined;
  onClick: () => void;
};

type MenuProps = {
  id: string | undefined;
  hidden: boolean;
};

type RootProps<T> = {
  ref: RefObject<T>;
};

type UseDropdownMenuReturnType<T> = {
  getButtonProps: () => ButtonProps;
  getMenuProps: () => MenuProps;
  getRootProps: () => RootProps<T>;
};

export function useDropdownMenu<T extends HTMLElement>(): UseDropdownMenuReturnType<T> {
  const [expanded, setExpanded] = useState(false);
  const root = useRef<T>(null);
  const id = useId();

  const getButtonProps = useCallback(
    () => ({
      'aria-expanded': expanded,
      'aria-controls': id,
      onClick: () => setExpanded((expanded) => !expanded),
    }),
    [expanded, id]
  );

  const getMenuProps = useCallback(
    () => ({
      id: id,
      hidden: !expanded,
    }),
    [expanded, id]
  );

  const getRootProps = useCallback(() => ({ ref: root }), []);

  useOnClickOutside(root, () => setExpanded(false));

  useEffect(() => {
    if (root.current) {
      if (expanded) {
        root.current.style.zIndex = '1000';
      } else {
        root.current.style.zIndex = '';
      }
    }
  }, [expanded]);

  return {
    getButtonProps,
    getMenuProps,
    getRootProps,
  };
}
