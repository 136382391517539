export function IconInfo(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={26}
      height={26}
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...props}
    >
      <g transform="translate(1 .523)" fill="none" fillRule="evenodd">
        <circle stroke="currentColor" cx={12} cy={12} r={12} />
        <g transform="translate(11.337 7)" fillRule="nonzero">
          <path fill="currentColor" d="M1.43 1.594V0H0v1.594h1.43zm0 9.883V3.148H0v8.329h1.43z" />
        </g>
      </g>
    </svg>
  );
}
