import { Messages, i18n } from '@lingui/core';
import { useRouter } from 'next/router';
import { useEffect } from 'react';

// Initialise Lingui example from the Lingui repository
// https://github.com/lingui/js-lingui/blob/main/examples/nextjs-babel/src/i18n.ts
export function useLinguiInit(messages: Messages) {
  const router = useRouter();
  const locale = router.locale || 'en';
  const isClient = typeof window !== 'undefined';

  if (!isClient && locale !== i18n.locale) {
    i18n.loadAndActivate({ locale, messages });
  }
  if (isClient && i18n.locale === undefined) {
    i18n.loadAndActivate({ locale, messages });
  }

  useEffect(() => {
    const localeDidChange = locale !== i18n.locale;
    if (localeDidChange) {
      i18n.loadAndActivate({ locale, messages });
    }
  }, [locale, messages]);

  return i18n;
}
