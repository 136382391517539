import Script from 'next/script';

export function MatomoScript() {
  return (
    <Script id="matomo-script">
      {`
          var _paq = window._paq = window._paq || [];

          /* tracker methods like "setCustomDimension" should be called before "trackPageView" */
        
          _paq.push(['trackPageView']);
        
          _paq.push(['enableLinkTracking']);
        
          (function() {
        
            var u="https://unisitetracker.unicef.io/";
        
            _paq.push(['setTrackerUrl', u+'matomo.php']);
        
            _paq.push(['setSiteId', '113']);
        
            var d=document, g=d.createElement('script'), s=d.getElementsByTagName('script')[0];
        
            g.async=true; g.src=u+'matomo.js'; s.parentNode.insertBefore(g,s);
        
          })();`}
    </Script>
  );
}
