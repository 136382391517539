import Head from 'next/head';
import { useRouter } from 'next/router';

export function AdditionalFonts() {
  const router = useRouter();
  const locale = router.locale;

  const getHref = (script: string) => {
    switch (script) {
      case 'Arabic':
        return `https://fonts.googleapis.com/css2?family=Noto+Kufi+${script}:wght@400..700&display=swap`;
      case 'Bengali':
      case 'Tamil':
        return `https://fonts.googleapis.com/css2?family=Noto+Sans+${script}:wdth,wght@62.5..100,400..700&display=swap`;
      case 'Devanagari':
        return `https://fonts.googleapis.com/css2?family=Noto+Sans+${script}:wght@400;500;600;700&display=swap`;
      default:
        return `https://fonts.googleapis.com/css2?family=Noto+Sans:wght@400;500;600;700&display=swap`;
    }
  };

  return (
    <Head>
      {locale === 'ar' && <link key="font-ar" href={getHref('Arabic')} rel="stylesheet" />}
      {locale === 'bn' && <link key="font-bn" href={getHref('Bengali')} rel="stylesheet" />}
      {locale === 'hi' && <link key="font-hi" href={getHref('Devanagari')} rel="stylesheet" />}
      {locale === 'ta' && <link key="font-ta" href={getHref('Tamil')} rel="stylesheet" />}
    </Head>
  );
}
