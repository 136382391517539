import { create } from 'zustand';

type Store = {
  prefersReducedMotion: boolean;
  setPrefersReducedMotion: (prefersReducedMotion: boolean) => void;

  headerTheme: 'white' | 'blue';
  setHeaderTheme: (headerTheme: 'white' | 'blue') => void;

  previousRoute: string | null;
  setPreviousRoute: (route: string | null) => void;
};

export const useStore = create<Store>((set) => ({
  prefersReducedMotion: false,
  setPrefersReducedMotion: (prefersReducedMotion) => set({ prefersReducedMotion }),

  headerTheme: 'blue',
  setHeaderTheme: (theme) => set({ headerTheme: theme }),

  previousRoute: null,
  setPreviousRoute: (route) => set({ previousRoute: route }),
}));
