export function IconGlobe(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width={26} height={26} xmlns="http://www.w3.org/2000/svg" {...props}>
      <g transform="translate(1 1)" stroke="currentColor" fill="none" fillRule="evenodd">
        <circle cx={12} cy={12} r={12} />
        <ellipse cx={12} cy={12} rx={7.2} ry={12} />
        <path d="M1.8 18.6h20.4M0 12h24M12 0v24M1.8 5.4h20.4" />
      </g>
    </svg>
  );
}
