import { i18n, Messages } from '@lingui/core';
import { isPresent } from 'ts-is-present';

export const localeMap = {
  ar: 'ar',
  bn: 'bn_BD',
  en: 'en',
  es: 'es',
  fr: 'fr',
  hi: 'hi',
  id: 'id_ID',
  pt: 'pt_BR',
  sw: 'sw_KE',
  ta: 'ta_IN',
};

export const localeLangMap = {
  ar: 'العربية',
  bn: 'বাংলা',
  en: 'English',
  es: 'Español',
  fr: 'Français',
  hi: 'हिन्दी',
  id: 'Bahasa Indonesia',
  pt: 'Português',
  sw: 'Kiswahili',
  ta: 'தமிழ்',
};

export type Locale = keyof typeof localeMap;

/**
 * Load messages for requested locale and activate it.
 * This function isn't part of the LinguiJS library because there are
 * many ways how to load messages — from REST API, from file, from cache, etc.
 */
export function activate(locale: string | undefined = 'en', messages: Messages): void {
  if (isPresent(locale)) {
    i18n.load(locale, messages as Messages);
    i18n.activate(locale);
  }
}

export async function loadMessages(locale: string | undefined = 'en'): Promise<Messages> {
  const { messages } = await import(
    `../../locales/${localeMap[locale as keyof typeof localeMap]}/messages`
  );

  return messages;
}
