export const visuallyHidden = {
  border: 0,
  clip: 'rect(0 0 0 0)',
  height: 1,
  margin: -1,
  overflow: 'hidden',
  padding: 0,
  position: 'absolute',
  width: 1,
  whiteSpace: 'nowrap',
  wordWrap: 'normal',
};

export const hoverStyles = {
  '&:hover': {
    color: '$blue0',
    textDecoration: 'underline',
  },
};

export const focusStyles = {
  '&:focus-visible, &.focus-visible': {
    outlineColor: 'currentColor',
    outlineStyle: 'dotted',
    outlineWidth: 3,
  },
};
