import { useRef, useEffect } from 'react';

function usePrevious<T>(currentValue: T): T | null {
  const previousRef = useRef<T | null>(null);

  useEffect(() => {
    previousRef.current = currentValue;
  }, [currentValue]);

  return previousRef.current;
}

export default usePrevious;
