import { css, styled } from '@/styles/stitches.config';
import { useRouter } from 'next/dist/client/router';
import dynamic from 'next/dynamic';

// use bundle splitting to avoid bloating the bundle size with SVG assets
const LogoArDesktop = dynamic(() => import(`@/assets/svg/unicef-logo/desktop/ar`));
const LogoArMobile = dynamic(() => import(`@/assets/svg/unicef-logo/mobile/ar`));
const LogoBnDesktop = dynamic(() => import(`@/assets/svg/unicef-logo/desktop/bn`));
const LogoBnMobile = dynamic(() => import(`@/assets/svg/unicef-logo/mobile/bn`));
const LogoEnDesktop = dynamic(() => import(`@/assets/svg/unicef-logo/desktop/en`));
const LogoEnMobile = dynamic(() => import(`@/assets/svg/unicef-logo/mobile/en`));
const LogoEsDesktop = dynamic(() => import(`@/assets/svg/unicef-logo/desktop/es`));
const LogoEsMobile = dynamic(() => import(`@/assets/svg/unicef-logo/mobile/es`));
const LogoFrDesktop = dynamic(() => import(`@/assets/svg/unicef-logo/desktop/fr`));
const LogoFrMobile = dynamic(() => import(`@/assets/svg/unicef-logo/mobile/fr`));
const LogoHiDesktop = dynamic(() => import(`@/assets/svg/unicef-logo/desktop/hi`));
const LogoHiMobile = dynamic(() => import(`@/assets/svg/unicef-logo/mobile/hi`));
const LogoIdDesktop = dynamic(() => import(`@/assets/svg/unicef-logo/desktop/id`));
const LogoIdMobile = dynamic(() => import(`@/assets/svg/unicef-logo/mobile/id`));
const LogoPtDesktop = dynamic(() => import(`@/assets/svg/unicef-logo/desktop/pt`));
const LogoPtMobile = dynamic(() => import(`@/assets/svg/unicef-logo/mobile/pt`));
const LogoSwDesktop = dynamic(() => import(`@/assets/svg/unicef-logo/desktop/sw`));
const LogoSwMobile = dynamic(() => import(`@/assets/svg/unicef-logo/mobile/sw`));

const Root = styled('div', {
  color: 'currentColor',
});

const MobileLogo = styled('span', {
  display: 'inline-block',
  height: '3rem',
  '@bp4': {
    display: 'none',
  },
});

const DesktopLogo = styled('span', {
  display: 'none',
  height: '3rem',
  '@bp4': {
    display: 'inline-block',
  },
});

const svgStyles = css({
  height: '100%',
  width: 'auto',
});

const LogoMobile = () => {
  const router = useRouter();
  switch (router.locale) {
    case 'ar':
      return <LogoArMobile className={svgStyles()} />;
    case 'bn':
      return <LogoBnMobile className={svgStyles()} />;
    case 'en':
      return <LogoEnMobile className={svgStyles()} />;
    case 'es':
      return <LogoEsMobile className={svgStyles()} />;
    case 'fr':
      return <LogoFrMobile className={svgStyles()} />;
    case 'hi':
      return <LogoHiMobile className={svgStyles()} />;
    case 'id':
      return <LogoIdMobile className={svgStyles()} />;
    case 'pt':
      return <LogoPtMobile className={svgStyles()} />;
    case 'sw':
      return <LogoSwMobile className={svgStyles()} />;
    default:
      return <LogoEnMobile className={svgStyles()} />;
  }
};

const LogoDesktop = () => {
  const router = useRouter();
  switch (router.locale) {
    case 'ar':
      return <LogoArDesktop className={svgStyles()} />;
    case 'bn':
      return <LogoBnDesktop className={svgStyles()} />;
    case 'en':
      return <LogoEnDesktop className={svgStyles()} />;
    case 'es':
      return <LogoEsDesktop className={svgStyles()} />;
    case 'fr':
      return <LogoFrDesktop className={svgStyles()} />;
    case 'hi':
      return <LogoHiDesktop className={svgStyles()} />;
    case 'id':
      return <LogoIdDesktop className={svgStyles()} />;
    case 'pt':
      return <LogoPtDesktop className={svgStyles()} />;
    case 'sw':
      return <LogoSwDesktop className={svgStyles()} />;
    default:
      return <LogoEnDesktop className={svgStyles()} />;
  }
};

export function UnicefLogo() {
  return (
    <Root>
      <MobileLogo>
        <LogoMobile />
      </MobileLogo>
      <DesktopLogo>
        <LogoDesktop />
      </DesktopLogo>
    </Root>
  );
}
