import { styled } from '@/styles/stitches.config';

export const Button = styled('button', {
  appearance: 'none',
  color: 'currentColor',
  fontFamily: 'inherit',
  fontSize: 'inherit',
  cursor: 'pointer',
  background: 'transparent',
});
