import { css, styled } from '@/styles/stitches.config';
import { Trans } from '@lingui/macro';
import CookieConsent from 'react-cookie-consent';

// cookie styling can also be partially find in globals.css
const CookieMessage = styled('p', {
  color: '$blue0',
  fontSize: '$1',
  fontWeight: '$semibold',
  maxWidth: '48rem',
  marginBlockEnd: 0,
});

const CookieAnchor = styled('a', {
  color: '$unicef',
});

const containerClasses = css({
  display: 'grid',
  gridTemplateColumns: '1fr min-content',
  gap: '$3',
  zIndex: 2000,
  position: 'fixed',
  width: '100%',
  bottom: 0,
  backgroundColor: '#e5e5e5',
  paddingBlock: '$4',
  paddingInline: '$4',
});

const contentClasses = css({
  display: 'flex',
  justifyContent: 'center',
  textAlign: 'center',
});

const buttonWrapperClasses = css({
  display: 'flex',
  height: '100%',
  alignItems: 'center',
  marginInlineEnd: '$3',
  justifyContent: 'flex-end',
});

const buttonClasses = css({
  display: 'block',
  outline: 0,
  border: 0,
  width: '$space$6',
  height: '$space$6',
  backgroundImage: 'url(/icon-close.svg)',
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'contain',
  backgroundPosition: 'center center',
  backgroundColor: 'transparent',
  cursor: 'pointer',
  padding: 0,
  margin: 0,
  marginInlineStart: '$2',
});

export function CookieBanner() {
  return (
    <CookieConsent
      disableStyles
      buttonText=""
      containerClasses={containerClasses()}
      contentClasses={contentClasses()}
      buttonWrapperClasses={buttonWrapperClasses()}
      buttonClasses={buttonClasses()}
      cookieName="unicefCookieConsent"
    >
      <CookieMessage>
        <Trans id="common.cookie-message">
          We use cookies and other identifiers to help improve your online experience. By using our
          website you agree to this. To learn more, including how to change your settings, see our{' '}
          <CookieAnchor href="https://www.unicef.org/legal#unicef-cookies-policy">
            cookies policy
          </CookieAnchor>
          .
        </Trans>
      </CookieMessage>
    </CookieConsent>
  );
}
