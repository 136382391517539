import { StyledLink } from '@/components/elements/styledLink';
import { LinkProps } from 'next/link';
import { MouseEventHandler, PropsWithChildren } from 'react';
import { ComposableStyledComponentProps } from '@/types/composable-styled-component';

type LinkToProps = ComposableStyledComponentProps<HTMLAnchorElement> &
  LinkProps &
  PropsWithChildren<{
    className?: string;
    href: string;
    hrefLang?: string;
    locale?: string;
    target?: HTMLAnchorElement['target'];
    rel?: HTMLAnchorElement['rel'];
    onClick?: MouseEventHandler<HTMLAnchorElement>;
    download?: string;
    dir?: string;
  }>;

/**
 * LinkTo wraps an a element. The Link component around it will replace the `href` on the a by a proper href.
 * This component exists to isolate this 'hack' or linting workaround to a single place. See:
 * https://github.com/jsx-eslint/eslint-plugin-jsx-a11y/blob/master/docs/rules/anchor-is-valid.md#case-i-use-nextjs-and-im-getting-this-error-inside-of-links
 *
 * @returns
 */
export function LinkTo(props: LinkToProps) {
  const {
    href,
    hrefLang,
    css,
    className,
    children,
    locale,
    target,
    rel,
    onClick,
    download,
    dir,
    ...rest
  } = props;

  if (download) {
    return (
      <StyledLink
        onClick={onClick}
        href={href}
        hrefLang={locale ?? hrefLang}
        target={target}
        rel={rel}
        download={download}
        className={className}
        css={css}
      >
        {children}
      </StyledLink>
    );
  }

  return (
    <StyledLink
      onClick={onClick}
      hrefLang={locale}
      className={className}
      css={css}
      target={target}
      rel={rel}
      dir={dir}
      href={href}
      locale={locale}
      passHref
      {...rest}
    >
      {children}
    </StyledLink>
  );
}
