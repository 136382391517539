import { createStitches } from '@stitches/react';

export const { styled, css, globalCss, keyframes, getCssText, theme, createTheme, config } =
  createStitches({
    media: {
      bp2: '(min-width: 25em)',
      bp4: '(min-width: 35em)',
      bp5: '(min-width: 40em)',
      bp6: '(min-width: 45em)',
      bp7: '(min-width: 50em)',
      bp8: '(min-width: 55em)',
      bp9: '(min-width: 64em)', // 1024px
      bp10: '(min-width: 65em)',
      bp11: '(min-width: 67.5em)',
      portrait: '(min-width: 23rem) and (orientation: portrait)',
      bph1: '(min-width: 25em), (min-height: 50em)',
    },
    theme: {
      colors: {
        blue0: '#00202c',
        blue1: '#00aeef',
        green0: '#00833d',
        green1: '#80bd41',
        yellow: '#ffc20e',
        orange: '#f26a21',
        red: '#e2231a',
        purple0: '#6a1e74',
        purple1: '#961a49',
        black: '#000000',
        white: '#fff',
        blackTransparent: 'rgba(0, 0, 0, 0.45)',
        shadows: 'rgba(0, 0, 0, 0.45)',
        unicef: '#00aeef',
      },
      fonts: {
        roboto:
          'Roboto, -apple-system, BlinkMacSystemFont, Segoe UI, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;',
        robotoSubset:
          "RobotoSubset, Roboto, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif",
        robotoFlex:
          "RobotoFlex, Roboto, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif",
        notoArabic:
          "'Noto Kufi Arabic', RobotoFlex, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif",
        notoSansTamil:
          "'Noto Sans Tamil', RobotoFlex, Roboto, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif",
        notoSansBengali:
          "'Noto Sans Bengali', RobotoFlex, Roboto, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif",
        notoSansHindi:
          "'Noto Sans Devanagari', RobotoFlex, Roboto, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif",
      },
      // variables defined in src/styles/globals.ts
      fontSizes: {
        1: 'calc(((var(--f--1-min) / 16) * 1rem) + (var(--f--1-max) - var(--f--1-min)) * var(--fluid-bp))',
        2: 'calc(((var(--f-0-min) / 16) * 1rem) + (var(--f-0-max) - var(--f-0-min)) * var(--fluid-bp))',
        3: 'calc(((var(--f-1-min) / 16) * 1rem) + (var(--f-1-max) - var(--f-1-min)) * var(--fluid-bp))',
        4: 'calc(((var(--f-2-min) / 16) * 1rem) + (var(--f-2-max) - var(--f-2-min)) * var(--fluid-bp))',
        5: 'calc(((var(--f-3-min) / 16) * 1rem) + (var(--f-3-max) - var(--f-3-min)) * var(--fluid-bp))',
        6: 'calc(((var(--f-4-min) / 16) * 1rem) + (var(--f-4-max) - var(--f-4-min)) * var(--fluid-bp))',
        7: 'calc(((var(--f-5-min) / 16) * 1rem) + (var(--f-5-max) - var(--f-5-min)) * var(--fluid-bp))',
      },
      fontWeights: {
        normal: 400,
        medium: 500,
        semibold: 600,
        bold: 700,
      },
      lineHeights: {
        1: 1,
        2: 1.25,
        3: 1.5,
      },
      // variables defined in src/styles/globals.ts
      space: {
        1: 'calc(((var(--fc-3xs-min) / 16) * 1rem) + (var(--fc-3xs-max) - var(--fc-3xs-min)) * var(--fluid-bp))',
        2: 'calc(((var(--fc-2xs-min) / 16) * 1rem) + (var(--fc-2xs-max) - var(--fc-2xs-min)) * var(--fluid-bp))',
        3: 'calc(((var(--fc-xs-min) / 16) * 1rem) + (var(--fc-xs-max) - var(--fc-xs-min)) * var(--fluid-bp))',
        4: 'calc(((var(--fc-s-min) / 16) * 1rem) + (var(--fc-s-max) - var(--fc-s-min)) * var(--fluid-bp))',
        5: 'calc(((var(--fc-m-min) / 16) * 1rem) + (var(--fc-m-max) - var(--fc-m-min)) * var(--fluid-bp))',
        6: 'calc(((var(--fc-l-min) / 16) * 1rem) + (var(--fc-l-max) - var(--fc-l-min)) * var(--fluid-bp))',
        7: 'calc(((var(--fc-xl-min) / 16) * 1rem) + (var(--fc-xl-max) - var(--fc-xl-min)) * var(--fluid-bp))',
        8: 'calc(((var(--fc-2xl-min) / 16) * 1rem) + (var(--fc-2xl-max) - var(--fc-2xl-min)) * var(--fluid-bp))',
        9: 'calc(((var(--fc-3xl-min) / 16) * 1rem) + (var(--fc-3xl-max) - var(--fc-3xl-min)) * var(--fluid-bp))',
      },
      radii: {
        1: '$space$2',
        2: '$space$4',
        3: '$space$6',
        4: '$space$8',
      },
      shadows: {
        1: '0 0 $space$1 0 $colors$shadows',
        2: '0 0 $space$2 0 $colors$shadows',
      },
    },
  });
