import { IconInfo } from '@/assets/svg/icon-info';
import { useStore } from '@/store';
import { styled } from '@/styles/stitches.config';
import { t, Trans } from '@lingui/macro';
import { visuallyHidden } from '../styles/predefined';
import { StyledLink } from './elements/styledLink';
import { LanguageDropdown } from './language-dropdown';
import { UnicefLogo } from './unicef-logo';

const Root = styled('div', {
  '--header-height': 'calc($space$9 - $space$3)',
  backgroundColor: 'var(--header-background)',
  minHeight: '100vh',
  position: 'relative',

  '@bp4': {
    '--header-height': '$space$8',
  },

  variants: {
    theme: {
      blue: {
        '--header-background': '$colors$unicef',
        '--header-foreground': '$colors$white',
      },
      white: {
        '--header-background': '$colors$white',
        '--header-foreground': '$colors$unicef',
      },
    },
  },
});

const HeaderRoot = styled('header', {
  position: 'relative',
  zIndex: '101',
  color: 'var(--header-foreground)',
  backgroundColor: 'var(--header-background)',
  height: 'var(--header-height)',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
});

const Header = styled('div', {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'flex-end',
  flexWrap: 'nowrap',
  /* Left and right padding are not the same because the logo has some whitespace built-in, */
  /* this compensates for the extra spaces on the outer edge. */
  paddingInlineStart: '$4',
  paddingInlineEnd: '$5',
  '@bp4': {
    alignItems: 'center',
  },
});

const Nav = styled('nav', {
  display: 'flex',
  alignItems: 'center',
  gap: '$4',

  '@supports not (gap: $4)': {
    '> *': {
      marginInlineEnd: '$4',
    },

    '&:last-child': {
      marginInlineEnd: 0,
    },
  },

  /* This hack aligns the bottom edges of the logo type and nav type on small screens */
  marginBlockEnd: '$1',

  '@bp4': {
    marginBlockEnd: 0,
  },
});

const Footer = styled('footer', {
  position: 'relative',
  display: 'flex',
  justifyContent: 'space-between',
  gap: '$4',
  paddingBlock: '$4',
  paddingInlineStart: '$4',
  paddingInlineEnd: '$5',
  background: '$unicef',
  color: '$white',
  zIndex: '101',
});

const FooterLinks = styled('ul', {
  margin: 0,
  padding: 0,
  listStyleType: 'none',
});

const FooterLinkItem = styled('li', {
  margin: 0,
  marginBlockEnd: '$2',
});

const LinkContents = styled('span', {
  display: 'flex',
  alignItems: 'center',
  lineHeight: '$2',
});

const LinkIcon = styled('span', {
  display: 'none',

  '@bp5': {
    display: 'flex',
    alignItems: 'center',
    marginInlineEnd: '$2',
  },
});

const VisuallyHidden = styled('span', visuallyHidden);

export function Layout(props: { children: React.ReactNode }) {
  const { children } = props;

  const theme = useStore((state) => state.headerTheme);

  return (
    <Root theme={theme}>
      <HeaderRoot>
        <Header>
          <StyledLink
            href="/stories"
            aria-label={t({
              id: 'layout.header-links.logo',
              message: 'Navigate to Stories Overview',
            })}
            css={{ display: 'block' }}
          >
            <UnicefLogo />
          </StyledLink>

          <Nav>
            <LanguageDropdown />

            <StyledLink href="/about" prefetch={false}>
              <LinkContents>
                <LinkIcon>
                  <IconInfo />
                </LinkIcon>
                <Trans id="common.about">About</Trans>
              </LinkContents>
            </StyledLink>
          </Nav>
        </Header>
      </HeaderRoot>

      <main>{children}</main>

      <Footer>
        <StyledLink
          href="/stories"
          aria-label={t({
            id: 'layout.footer-links.logo',
            message: 'Navigate to Stories Overview',
          })}
          css={{
            '&:hover': {
              color: 'currentColor',
            },
          }}
        >
          <UnicefLogo />
        </StyledLink>

        <VisuallyHidden as="h3">
          <Trans id="layout.footer-links.sr-only-heading">Secondary Navigation</Trans>
        </VisuallyHidden>

        <FooterLinks>
          <FooterLinkItem>
            <LanguageDropdown direction="up" alignment="right" />
          </FooterLinkItem>
          <FooterLinkItem>
            <StyledLink href="/about" prefetch={false}>
              <Trans id="common.about">About</Trans>
            </StyledLink>
          </FooterLinkItem>
          <FooterLinkItem>
            <StyledLink href="https://www.unicef.org/">
              <Trans id="common.unicef-global">UNICEF Global</Trans>
            </StyledLink>
          </FooterLinkItem>
          <FooterLinkItem>
            <StyledLink href="https://www.unicef.org/globalinsight/">
              <Trans id="common.unicef.ogip">UNICEF OGIP</Trans>
            </StyledLink>
          </FooterLinkItem>
          <FooterLinkItem>
            <StyledLink href="/terms-of-use" prefetch={false}>
              <Trans id="common.terms-of-use">Terms of use</Trans>
            </StyledLink>
          </FooterLinkItem>
        </FooterLinks>
      </Footer>
    </Root>
  );
}
