import { useRouter } from 'next/router';
import { useEffect } from 'react';

export default function useBodyDirection() {
  const { locale } = useRouter();

  useEffect(() => {
    const dir = locale === 'ar' ? 'rtl' : 'ltr';
    document.querySelector('body')?.setAttribute('dir', dir);
  }, [locale]);
}
