import { useStore } from '@/store';
import { useRouter } from 'next/router';
import usePrevious from './use-previous';
import { useEffect } from 'react';

export function useTrackPreviousRoute(): void {
  const router = useRouter();

  // Track the previous route so we know how to animate the route change
  const setPreviousRoute = useStore((state) => state.setPreviousRoute);
  const previousRoute = usePrevious(router.asPath);

  useEffect(() => {
    setPreviousRoute(previousRoute);
  }, [previousRoute, setPreviousRoute]);
}
