import { globalCss } from '@/styles/stitches.config';
// import typography from './type.css';

export const globalStyles = globalCss({
  '@font-face': [
    {
      fontFamily: 'RobotoSubset',
      fontDisplay: 'swap',
      src: "url('/fonts/Roboto-Regular-subset.woff2') format('woff2'), url('/fonts/Roboto-Regular-subset.zopfli.woff') format('woff')",
      unicodeRange:
        'U+0000, U+0002, U+000D, U+0020-007E, U+00A0-00FF, U+0131, U+0152-0153, U+0178, U+02BC, U+02C6, U+02DA, U+02DC, U+2000-200B, U+2010-2011, U+2013-2015, U+2017-201E, U+2020-2022, U+2025-2027, U+2030, U+2032-2033, U+2039-203A, U+203C, U+2044, U+2074, U+20AC, U+2122, U+2212, U+FEFF, U+FFFD',
    },
    {
      fontFamily: 'RobotoFlex',
      fontDisplay: 'swap',
      fontWeight: '100 1000',
      fontStretch: '25% 151%',
      src: "url('/fonts/RobotoFlex-subset.woff2') format('woff2-variations'), url('/fonts/RobotoFlex-subset.zopfli.woff') format('woff-variations')",
      unicodeRange:
        'U+0000, U+000D, U+0020-007E, U+00A0-00FF, U+0152-0153, U+0178, U+02BC, U+02C6, U+02DA, U+02DC, U+2010, U+2013-2015, U+2018-201A, U+201C-201E, U+2020-2022, U+2026, U+2030, U+2032-2033, U+2039-203A, U+2044, U+2052, U+2074, U+20AC, U+2122, U+2212, U+2215',
    },
  ],
  ':root': {
    /**
     * Type scale
     * @link https://utopia.fyi/type/calculator?c=320,16,1.333,1140,20,1.5,5,2,&s=0.75|0.5|0.25,1.5|2|3|4|6,s-l *
     **/
    '--fluid-min-width': 320,
    '--fluid-max-width': 1140,
    '--fluid-screen': '100vw',
    '--fluid-bp': `calc((var(--fluid-screen) - var(--fluid-min-width) / 16 * 1rem) / (var(--fluid-max-width) - var(--fluid-min-width)))`,

    '@bp11': {
      '--fluid-screen': 'calc(var(--fluid-max-width) * 1px)',
    },

    '--f--1-min': 12,
    '--f--1-max': 13.33,

    '--f-0-min': 16,
    '--f-0-max': 20,

    '--f-1-min': 21.33,
    '--f-1-max': 30,

    '--f-2-min': 28.43,
    '--f-2-max': 45,

    '--f-3-min': 37.9,
    '--f-3-max': 67.5,

    '--f-4-min': 50.52,
    '--f-4-max': 101.25,

    '--f-5-min': 67.34,
    '--f-5-max': 151.88,

    /**
     * Spacing scale
     * Derived from the type scale in './type.css'
     * @link https://utopia.fyi/space/calculator?c=320,16,1.5,1140,20,1.5,5,2,&s=0.75|0.5|0.25,1.5|2|3|4|6,
     **/
    '--fc-3xs-min': '(var(--fc-s-min) * 0.25)',
    '--fc-3xs-max': '(var(--fc-s-max) * 0.25)',

    '--fc-2xs-min': '(var(--fc-s-min) * 0.5)',
    '--fc-2xs-max': '(var(--fc-s-max) * 0.5)',

    '--fc-xs-min': '(var(--fc-s-min) * 0.75)',
    '--fc-xs-max': '(var(--fc-s-max) * 0.75)',

    '--fc-s-min': '(var(--f-0-min))',
    '--fc-s-max': '(var(--f-0-max))',

    '--fc-m-min': '(var(--fc-s-min) * 1.5)',
    '--fc-m-max': '(var(--fc-s-max) * 1.5)',

    '--fc-l-min': '(var(--fc-s-min) * 2)',
    '--fc-l-max': '(var(--fc-s-max) * 2)',

    '--fc-xl-min': '(var(--fc-s-min) * 3)',
    '--fc-xl-max': '(var(--fc-s-max) * 3)',

    '--fc-2xl-min': '(var(--fc-s-min) * 4)',
    '--fc-2xl-max': '(var(--fc-s-max) * 4)',

    '--fc-3xl-min': '(var(--fc-s-min) * 6)',
    '--fc-3xl-max': '(var(--fc-s-max) * 6)',

    /**
     * Variable font settings
     */
    '--roboto-flex-opsz': 16,
    '--roboto-flex-slnt': 0,
    '--roboto-flex-grad': 0,
    '--roboto-flex-xtra': 468,
    '--roboto-flex-xopq': 96,
    '--roboto-flex-yopq': 79,
    '--roboto-flex-ytlc': 514,
    '--roboto-flex-ytuc': 712,
    '--roboto-flex-ytas': 750,
    '--roboto-flex-ytde': -203,
    '--roboto-flex-ytfi': 738,
  },
  'body, #__next': {
    minHeight: '100vh',
  },
  body: {
    fontFamily: '$roboto',
    fontSize: '$2',
  },
  '.fonts-loaded-1 body': {
    fontFamily: '$robotoSubset',
  },
  '.fonts-loaded-2 body': {
    fontFamily: '$robotoFlex',
  },
  '[lang="ar"] body': {
    fontFamily: '$notoArabic',
  },
  '.fonts-loaded-2 *': {
    fontVariationSettings:
      "'opsz' var(--roboto-flex-opsz), 'slnt' var(--roboto-flex-slnt), 'GRAD' var(--roboto-flex-grad), 'XTRA' var(--roboto-flex-xtra), 'XOPQ' var(--roboto-flex-xopq), 'YOPQ' var(--roboto-flex-yopq), 'YTLC' var(--roboto-flex-ytlc), 'YTUC' var(--roboto-flex-ytuc), 'YTAS' var(--roboto-flex-ytas), 'YTDE' var(--roboto-flex-ytde), 'YTFI' var(--roboto-flex-ytfi)",
    fontSynthesis: 'none',
    fontOpticalSizing: 'none',
  },
  '[lang=ar] body': {
    fontFamily: '$notoArabic',
  },
  '[lang="bn"] body': {
    fontFamily: '$notoSansBengali',
  },
  '[lang="ta"] body': {
    fontFamily: '$notoSansTamil',
  },
  '[lang="hi"] body': {
    fontFamily: '$notoSansHindi',
  },
  // reset elements back to their original styles
  '.fonts-loaded-2 h1, .fonts-loaded-2 h2, .fonts-loaded-2 h3, .fonts-loaded-2 h4, .fonts-loaded-2 h5, .fonts-loaded-2 h6, .fonts-loaded-2 strong, .fonts-loaded-2 b, .fonts-loaded-2 th':
    {
      '--roboto-flex-wght': 700,
    },
  'em, address, i, cite, dfn, var': {
    '--roboto-flex-slnt': -10,
  },
  'h1, h2, h3, h4, p': {
    marginBlockStart: 0,
  },
  'h1, h2, h3': {
    lineHeight: '$2',
  },
  h1: {
    fontSize: '$5',
    marginBlockEnd: '$5',
  },
  h2: {
    fontSize: '$4',
  },
  h3: {
    fontSize: '$3',
  },
});
