import { MatomoScript } from '@/assets/matomo-script';
import { AdditionalFonts } from '@/components/additional-fonts';
import { CookieBanner } from '@/components/cookie-banner';
import { FacebookConnect } from '@/components/fb-connect';
import { GoogleAnalyticsTag } from '@/components/gtag';
import { Layout } from '@/components/layout';
import { globalStyles } from '@/styles/globals';
import useBodyDirection from '@/utils/use-body-direction';
import { usePrefersReducedMotion } from '@/utils/use-prefers-reduced-motion';
import { useTrackPreviousRoute } from '@/utils/use-track-previous-route';
import { useLinguiInit } from '@/utils/useLinguiInit';
import { i18n, Messages } from '@lingui/core';
import { I18nProvider } from '@lingui/react';
import 'focus-visible';
import { AppProps, NextWebVitalsMetric } from 'next/app';
import Head from 'next/head';
import React from 'react';
import 'sanitize.css/sanitize.css';

if (typeof window !== 'undefined') {
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  const smoothscroll = require('smoothscroll-polyfill');
  smoothscroll.polyfill();
}

if (typeof window !== 'undefined' && process.env.NODE_ENV !== 'production') {
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  const ReactDOM = require('react-dom');
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  const axe = require('@axe-core/react');
  axe(React, ReactDOM, 1000);
}

export default function App({ Component, pageProps }: AppProps<{ messages: Messages }>) {
  globalStyles();

  useLinguiInit(pageProps.messages);
  useTrackPreviousRoute();
  usePrefersReducedMotion();

  useBodyDirection();

  return (
    <>
      <Head>
        <meta key="viewport" name="viewport" content="width=device-width, viewport-fit=cover" />
      </Head>

      <AdditionalFonts />

      {process.env.NODE_ENV === 'production' && (
        <>
          <MatomoScript />
          <GoogleAnalyticsTag />
          <FacebookConnect />
        </>
      )}

      <I18nProvider i18n={i18n}>
        <Layout>
          <Component {...pageProps} />
        </Layout>

        <CookieBanner />
      </I18nProvider>
    </>
  );
}

export function reportWebVitals({ id, name, label, value }: NextWebVitalsMetric): void {
  if (process.env.NODE_ENV === 'production' && label === 'web-vital' && window.gtag) {
    window.gtag('event', name, {
      event_category: 'Web Vitals',
      value: Math.round(name === 'CLS' ? value * 1000 : value), // values must be integers
      event_label: id, // id unique to current page load
      non_interaction: true, // avoids affecting bounce rate.
    });
  }
}
